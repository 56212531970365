var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Image thumbnails"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeThumbnails) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("You can use prop ")]), _c('code', [_vm._v("thumbnail")]), _c('span', [_vm._v("to give an image a rounded light border appearance.")])]), _c('b-container', {
    staticClass: "p-1 bg-dark",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('b-img', {
    attrs: {
      "thumbnail": "",
      "fluid": "",
      "src": require('@/assets/images/avatars/1.png'),
      "alt": "Image 1"
    }
  })], 1), _c('b-col', [_c('b-img', {
    attrs: {
      "thumbnail": "",
      "fluid": "",
      "src": require('@/assets/images/avatars/2.png'),
      "alt": "Image 2"
    }
  })], 1), _c('b-col', [_c('b-img', {
    attrs: {
      "thumbnail": "",
      "fluid": "",
      "src": require('@/assets/images/avatars/3.png'),
      "alt": "Image 3"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }